import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AppRoutes from "./Routes";
import ErrorBoundary from "./common/ErrorBoundary";


function App() {
  return (
    <>
      <Navbar className="border-bottom" bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">Lost in the Wild Administration</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Dashboard</Nav.Link>
            <Nav.Link href="/dashboard">Trip Viewer</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>
    </>
  );
}

export default App;