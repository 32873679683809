import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner'

const Home = lazy(() => import("./common/Home"));
const Dashboard = lazy(() => import("./common/Dashboard"));
const NotFound = lazy(() => import("./common/NotFound"));

export default function AppRoutes() {
    return (
        <Suspense fallback={
            <div className="align-self-center">
                <Spinner animation="border" variant="secondary" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        }>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}