
import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "lostinthewild",
       // endpoint: process.env.REACT_ADMIN_APP_API_URL,
       // region: process.env.REACT_ADMIN_APP_API_REGION
       endpoint: "https://admin-api.willsandbox.lostinthewild.co",
       region: "eu-west-1"
      },
    ]
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
);
